<div class="flex size-full items-start">
  <nav
    class="navbar flex h-full w-16 lg:w-56 shrink-0 flex-col gap-8 overflow-y-auto border-r px-0 lg:pr-0 lg:pl-12 pt-12 pb-6">
    <div class="px-5">
      <img
        src="./assets/svg/bodoni-prototype-logo.svg"
        alt="Bodoni Prototype"
        class="w-0 lg:w-[180px] lg:h-10" />
      <img
        src="./../../../favicon.ico"
        alt="Bodoni Prototype"
        class="w-6 lg:w-0 mt-1" />
    </div>

    <div class="flex flex-col grow gap-1.5">
      <a
        class="navbar-link"
        routerLinkActive="active"
        [routerLink]="['/explore']">
        <svg-icon
          class="text-slate-500 min-w-5 min-h-5 size-5"
          key="compass-line"
          routerLinkActive="!text-accent bg-teal-50"></svg-icon>
        <span class="hidden lg:flex min-w-max transition-all">{{ 'i18n.SidebarMenu.explore' | translate }}</span>
      </a>
      <a
        class="navbar-link"
        routerLinkActive="active"
        [routerLink]="['/news']">
        <svg-icon
          class="text-slate-500 min-w-5 min-h-5 size-5"
          key="news-line"
          routerLinkActive="!text-accent bg-teal-50"></svg-icon>
        <span class="hidden lg:flex min-w-max transition-all truncate">{{ 'i18n.SidebarMenu.news' | translate }}</span>
      </a>
      <a
        class="navbar-link"
        routerLinkActive="active"
        [routerLink]="['/coeditor']">
        <svg-icon
          class="text-slate-500 min-w-5 min-h-5 size-5"
          key="quill-pen-line"
          routerLinkActive="!text-accent bg-teal-50"></svg-icon>
        <span class="hidden lg:flex min-w-max transition-all">{{ 'i18n.SidebarMenu.coeditor' | translate }}</span>
      </a>
      <div
        class="mt-auto hidden lg:block w-full max-w-40 text-sm leading-5 font-medium text-rest [&_a]:underline"
        [innerHTML]="'i18n.SidebarMenu.about' | translate"></div>

      <div class="!gap-4 text-rest text-sm mt-12">
        <!-- <svg-icon key="logout"></svg-icon>
        {{ 'i18n.SidebarMenu.quit' | translate }} -->
        <span class="hidden capitalize lg:flex min-w-max transition-all">
          {{ version }}
        </span>
      </div>
    </div>
  </nav>
  <router-outlet class="hidden" />
</div>
